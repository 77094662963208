import { CarCheckData } from "./types";

/**
 * map of vehicle make and models
 * stored in lowercase
 */
export const vehicleMakeAndModels = {
  "alpha-romeo": ["giulietta", "giulia", "stelvio"],
  "aston-martin": ["vantage-roadster"],
  audi: ["q2", "a3", "q3", "a4", "a5", "a6", "q7", "q8"],
  baic: ["x3", "x5", "x7"],
  bestune: ["t33", "b70", "t55", "t77-pro"],
  bmw: [
    "x2",
    "118",
    "218-i",
    "x1",
    "320",
    "520",
    "ix1",
    "420",
    "x3",
    "x4",
    "x5",
    "ix",
    "x6-m",
    "bmw-x7",
    "7-series",
    "8-series",
  ],
  byd: ["f3"],
  changan: [
    "alsvin",
    "eado",
    "cs-15",
    "cs-55",
    "cs-35-plus",
    "cs55-plus",
    "uni-t",
  ],
  chery: [
    "arrizo-5",
    "tiggo3",
    "tiggo-7",
    "tiggo-7-pro",
    "tiggo-8",
    "tiggo-8-pro",
  ],
  chevrolet: ["optra", "captiva"],
  citroen: [
    "c-elysee",
    "c3",
    "c3-aircross-shape-facelift",
    "c4",
    "c4x",
    "c5-aircross-shape-facelift",
    "c5-aircross",
  ],
  cupra: ["leon-cupra", "formentor"],
  dfm: ["aeolus-a30", "e70-pro", "venucia-star"],
  dfsk: ["330s", "eagle-580", "eagle-pro"],
  ds: ["ds3", "ds4", "ds7"],
  fiat: ["500", "500c", "500x", "tipo"],
  ford: ["ecosport", "focus"],
  forthing: ["t5+evo"],
  gac: ["emkoo", "empow", "gs3++emzoom", "gs4"],
  geely: ["coolray", "geometry-c", "gx3-pro", "okavango"],
  haval: ["haval-h6", "jolion"],
  honda: ["accord", "city", "crv"],
  hyundai: [
    "accent-hci-shape-new-generation",
    "accent-rb",
    "bayon",
    "creta-su2",
    "elantra-cn7",
    "elantra-hd",
    "i10",
    "i20",
    "tucson-shape-new-generation",
    "tucson-shape-new-generationelantra-cn7",
  ],
  jac: ["j7", "js3", "js4", "s2"],
  jaguar: ["f-pace", "f-type", "xe", "xf"],
  jeep: ["grand-cherokee", "renegade", "wrangler"],
  jetour: ["dashing", "x70", "x70-plus", "x90-plus", "x95"],
  kaiyi: ["kaiyi-x3", "kaiyi-x3-pro"],
  kia: ["grand-cerato", "sorento", "sportage", "xceed"],
  lada: ["granta"],
  "land-rover": [
    "defender",
    "discovery",
    "discovery-sport",
    "evoque",
    "range-rover",
    "sport",
    "velar",
    "vogue",
  ],
  leapmotor: ["c11", "t03"],
  lexus: ["es-350", "ls-500", "lx-570", "rx"],
  maserati: ["ghibli", "grecale", "levante", "quattroporte"],
  mazda: ["cx-3", "mazda-3"],
  mercedes: [
    "a-200",
    "a-class",
    "amg-gt",
    "b-200",
    "c-180",
    "c-200",
    "cla-200",
    "cls-class",
    "e-200",
    "e-300",
    "e-53-amg+",
    "g-class",
    "gla",
    "glc-200",
    "glc-200-amg-imported",
    "glc-300",
    "gle",
    "gls",
    "maybach",
    "s-450",
  ],
  mini: ["cooper", "country-man", "mini-cooper-s"],
  mitsubishi: ["attrage", "eclipse-cross", "mirage", "xpander"],
  "moris-garage": ["mg-5", "mg-6", "mg-hs", "rx5", "rx5-plus", "zs"],
  nissan: ["juke", "patrol", "qashqai", "sentra", "sunny"],
  opel: ["corsa", "crossland", "grandland", "mokka"],
  peugeot: ["2008", "3008", "301", "408", "5008", "508"],
  porsche: [
    "718",
    "911",
    "boxster",
    "boxster-s",
    "cayenne",
    "cayman",
    "cayman-s",
    "macan",
    "panamera",
    "taycan",
  ],
  proton: ["saga"],
  renault: [
    "austral",
    "duster",
    "logan",
    "megane",
    "sandero",
    "sandero-step-way",
    "taliant",
  ],
  seat: ["arona", "ateca", "ibiza", "leon", "tarraco"],
  skoda: [
    "fabia",
    "kamiq",
    "karoq-shape-facelift",
    "kodiaq",
    "octavia-a8",
    "skoda-scala",
    "superb",
  ],
  soueast: [
    "dx3",
    "dx5",
    "dx7",
    "dx7-nova",
    "dx7-prime",
    "dx8s-coupe",
    "soueast-a5",
  ],
  "ssang-yong": ["tivoli", "tivoli-xlv"],
  subaru: ["impreza", "xv"],
  suzuki: [
    "alto",
    "baleno",
    "celerio",
    "ciaz",
    "ertiga",
    "fronx",
    "jimny",
    "s-presso",
    "swift",
    "swift-dzire",
    "swift-shape-facelift",
    "vitara",
  ],
  toyota: [
    "belta",
    "c-hr",
    "camry",
    "corolla",
    "fortuner",
    "land-cruiser",
    "prado",
    "rav4",
    "rumion",
    "rush",
    "yaris",
  ],
  volkswagen: ["golf", "passat", "t-roc", "tiguan", "touareg"],
  volvo: ["v60", "xc-40", "xc60", "xc90"],
} as const;

export type VehicleMake = keyof typeof vehicleMakeAndModels;
export type VehicleModel = (typeof vehicleMakeAndModels)[VehicleMake][number];
export type VehicleModelOptions = Record<VehicleMake, readonly VehicleModel[]>;
export const vehicleModelOptions: VehicleModelOptions = vehicleMakeAndModels;
export const vehicleMakeOptions = Object.keys(
  vehicleMakeAndModels
) as VehicleMake[];

/**
 * Type guard for car make
 */
export const isKnownVehicleMake = (input: string): input is VehicleMake =>
  vehicleMakeOptions.includes(input.toLowerCase() as VehicleMake);

export const isKnownVehicleModel = (
  make: VehicleMake,
  model: string
): model is VehicleModel => {
  const models: string[] = vehicleMakeAndModels[make]
    ? [...vehicleMakeAndModels[make]]
    : [];
  return models.includes(model.toLowerCase());
};

export const getVehicleMakeAndModel = (
  data: Partial<CarCheckData>
): [VehicleMake | undefined, VehicleModel | undefined] => {
  const make =
    data.vehicleMake && isKnownVehicleMake(data.vehicleMake)
      ? data.vehicleMake
      : undefined;
  const model =
    make && data.vehicleModel && isKnownVehicleModel(make, data.vehicleModel)
      ? data.vehicleModel
      : undefined;

  return [make, model];
};
