import { Input, InputHandle, InputProps } from "@packages/ui";
import { Prisma } from "@packages/data-model";
import { useTranslations } from "@services/strings";
import {
  ChangeEventHandler,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRtl } from "@hooks/useRtl";

export type DecimalInputProps = Omit<InputProps, "onChange" | "value"> & {
  formatNumber?: (n: number) => string;
  onChange: (value: Prisma.Decimal) => void;
  parseNumber?: (s: string) => number;
  value: Prisma.Decimal;
};

export const DecimalInput = (props: DecimalInputProps) => {
  const rtl = useRtl();
  const element = useRef<HTMLInputElement>();

  const { currencySymbol, formatNumber, parseNumber } = useTranslations();

  const [value, setValue] = useState<Prisma.Decimal>(props.value);
  const [range, setRange] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    const [start, end] = range;
    if (!element.current || (start <= 0 && end <= 0)) {
      return;
    }

    element.current.setSelectionRange(range[0], range[1]);
  }, [range]);

  // TODO Generalize to allow for swapping periods and commas as decimal separators
  const commas = useCallback(
    (d: Prisma.Decimal): number =>
      (props.formatNumber ?? formatNumber)(d.toNumber()).match(/,/g)?.length ??
      0,
    [formatNumber, props.formatNumber]
  );

  const getDecimalValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ): Prisma.Decimal => {
    const eventValue = e.target.value.replace(currencySymbol, "");
    const input = (props.parseNumber ?? parseNumber)(eventValue);
    const decimal = new Prisma.Decimal(isNaN(input) ? 0 : input);
    return decimal;
  };

  const formatOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { selectionStart, selectionEnd } = e.target;
    const decimal = getDecimalValue(e);

    const prevCommas = commas(value);
    const eventCommas = commas(decimal);
    const offset = eventCommas - prevCommas;

    props.onChange(decimal);
    setValue(decimal);

    if (!selectionStart || !selectionEnd) {
      return;
    }

    setRange([selectionStart + offset, selectionEnd + offset]);
  };

  return (
    <Input
      {...props}
      type="text"
      dir={rtl ? "rtl" : "ltr"}
      value={formatNumber(value.toNumber())}
      onChange={formatOnChange}
      ref={element as Ref<InputHandle>}
    />
  );
};
