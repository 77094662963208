import Image from "next/image";
import { AppStoreButton } from "../app-store-button";
import PhoneScreen2 from "@public/app/phone_screen_2.png";
import PhoneScreen2AR from "@public/app/phone_screen_2_ar.png";
import { animated, useInView } from "@react-spring/web";
import { useTranslations } from "@services/strings";
import { useRtl } from "@hooks/useRtl";

export const CTASection = () => {
  const isRtl = useRtl();
  const { t } = useTranslations();

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: 100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    {
      rootMargin: "-10% 0%",
      once: true,
    },
  );

  return (
    <section className="container max-w-6xl flex flex-col py-16 overflow-hidden">
      <animated.div ref={ref} style={springs} className="flex justify-center">
        <Image
          src={isRtl ? PhoneScreen2AR : PhoneScreen2}
          alt="app"
          className="self-center w-72 md:w-96"
        />
      </animated.div>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-10">
          <h2 className="font-semibold text-4xl text-center text-slate-800">
            {t("pages.landing.app-cta.title")}
          </h2>
          <span className="text-center text-slate-500">
            {t("pages.landing.app-cta.description")}
          </span>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-row gap-4 place-self-center">
            <AppStoreButton store="apple" />
            <AppStoreButton store="android" />
          </div>
        </div>
      </div>
    </section>
  );
};
