import { Prisma } from "@prisma/client";
import { ipmt, pmt, ppmt } from "financial";
import { getPrincipal } from "../loans/calculations";
import {
  BORROWER_MAX_AGE,
  REDUCING_INTEREST_RATE_HEL,
  MAX_ADDITIONAL_INCOME_RATIO,
  MAX_INCOME_DEBT_RATIO,
  MAX_LOAN_VALUE_RATIO,
  LOAN_TERMS,
  MINIMUM_LOAN_AMOUNT,
} from "../loans/constants";
import { Borrower, Payment } from "../loans/types";
import {
  HomeEquityCheckData,
  HomeEquityLoanParameters,
  IneligibleReason,
} from "./types";

/** Get a borrower's total monthly borrowing capacity */
export const getCapacity = (
  borrower: Borrower,
  maxIncomeDebtRatio = MAX_INCOME_DEBT_RATIO
): Prisma.Decimal =>
  Prisma.Decimal.max(
    getIncome(borrower).times(maxIncomeDebtRatio).minus(borrower.debt),
    0
  );

/**
 * Get a borrower's total monthly income, including up to
 * {@link MAX_ADDITIONAL_INCOME_RATIO} of additional income
 **/
export const getIncome = ({
  income,
  additionalIncome,
}: Borrower): Prisma.Decimal => {
  return income.plus(
    Prisma.Decimal.min(
      additionalIncome,
      income.times(MAX_ADDITIONAL_INCOME_RATIO)
    )
  );
};

export type HomeEquityLoanData = Pick<
  HomeEquityCheckData,
  "additionalIncome" | "age" | "debt" | "income" | "propertyPrice"
>;

export const getHomeEquityLoanParameters = (
  data: HomeEquityLoanData,
  selectedPrincipal?: number
): HomeEquityLoanParameters[] => {
  const borrower = { ...data };
  const capacity = getCapacity(borrower);

  const effectiveDebt = borrower.debt;
  const effectiveIncome = getIncome(borrower);

  const maxPrincipal = data.propertyPrice.times(MAX_LOAN_VALUE_RATIO);
  const interestRate = REDUCING_INTEREST_RATE_HEL.toNumber();

  const maxAge = BORROWER_MAX_AGE;

  const terms = LOAN_TERMS.filter((term) =>
    borrower.age.plus(new Prisma.Decimal(term).dividedBy(12)).lessThan(maxAge)
  );

  const parameters = terms.map((term): HomeEquityLoanParameters => {
    const nper = term;
    let principal = selectedPrincipal ?? maxPrincipal.toNumber();
    let payment = pmt(interestRate, nper, principal);

    // Ensure installment payment does not exceed debt capacity
    if (capacity.lessThan(-payment)) {
      principal = getPrincipal(
        REDUCING_INTEREST_RATE_HEL.toNumber(),
        nper,
        capacity.toNumber()
      );
      payment = pmt(interestRate, term, principal);
    }

    const decimalPayment = new Prisma.Decimal(payment).abs();
    const total = decimalPayment.times(term);
    const interest = total.minus(principal);

    const schedule = Array.from(Array(term)).map(
      (_, i): Payment => ({
        period: i + 1,
        interest: new Prisma.Decimal(
          ipmt(interestRate, i + 1, nper, principal)
        ),
        principal: new Prisma.Decimal(
          ppmt(interestRate, i + 1, nper, principal)
        ),
      })
    );

    return {
      borrower,
      capacity,
      effectiveDebt,
      effectiveIncome,
      interest,
      interestRate: REDUCING_INTEREST_RATE_HEL,
      payment: decimalPayment,
      principal: new Prisma.Decimal(principal),
      propertyValue: data.propertyPrice,
      schedule,
      term: new Prisma.Decimal(term),
      total,
    };
  });

  return parameters;
};

export const getLoanPaymentSchedule = (
  principal: Prisma.Decimal,
  term: number,
  interestRate: Prisma.Decimal
) => {
  const _principal = principal.toNumber();
  const _interestRate = interestRate.toNumber();
  const nper = term;
  const payment = pmt(_interestRate, nper, _principal);

  const decimalPayment = new Prisma.Decimal(payment).abs();
  const total = decimalPayment.times(term);
  const interest = total.minus(principal);

  const schedule = Array.from(Array(term)).map(
    (_, i): Payment => ({
      period: i + 1,
      interest: new Prisma.Decimal(
        ipmt(_interestRate, i + 1, nper, _principal)
      ),
      principal: new Prisma.Decimal(
        ppmt(_interestRate, i + 1, nper, _principal)
      ),
    })
  );

  return {
    interest,
    payment: decimalPayment,
    schedule,
    term,
    total,
  };
};

const defaultHomeEquityCheckData = {
  additionalIncome: new Prisma.Decimal(0),
  age: new Prisma.Decimal(21),
  compound: null,
  debt: new Prisma.Decimal(0),
  employmentStatus: "full-time" as const,
  propertyLocation: "governorate" as const,
  propertyRegistered: true,
  residency: "domestic" as const,
};

export const getAnonymousHomeEquityCheckData = (
  income: Prisma.Decimal,
  propertyPrice: Prisma.Decimal,
  selectedPrincipal?: number
): HomeEquityLoanParameters[] => {
  const data: HomeEquityCheckData = {
    ...defaultHomeEquityCheckData,
    income,
    propertyPrice,
  };
  return getHomeEquityLoanParameters(data, selectedPrincipal);
};

export const getHomeEquityIneligibleReasons = (
  data: HomeEquityCheckData
): IneligibleReason[] => {
  const ineligibleReasons: IneligibleReason[] = [];

  // If property is not in a compound
  if (data.propertyLocation !== "urban-city") {
    ineligibleReasons.push("non-compund");
  }

  // If the borrower is too old
  if (
    data.age.greaterThan(
      BORROWER_MAX_AGE - Math.min(...LOAN_TERMS.map((t) => t / 12))
    )
  ) {
    ineligibleReasons.push("age");
  }

  // If the borrower's income is too low compared to their debt
  const capacity = getCapacity(data);
  if (capacity.lessThanOrEqualTo(0)) {
    ineligibleReasons.push("income-debt-ratio");
  }

  // If the maximum principal is lower than the minimum HEL loan amount
  const parameters = getHomeEquityLoanParameters(data);
  if (
    parameters.some((p) =>
      p.principal.greaterThanOrEqualTo(MINIMUM_LOAN_AMOUNT)
    ) === false
  ) {
    ineligibleReasons.push("loan-amount");
  }

  return ineligibleReasons;
};
