import Image from "next/image";
import { Testimonials } from "./testimonials";
import BlueShapes from "@public/hel/shapes.svg";
import { animated, useInView } from "@react-spring/web";
import { useTranslations } from "@services/strings";
import { Separator } from "@packages/ui";
import Badge from "@public/app/mnzl_badge.svg";

export const TestimonialSection = () => {
  const { t } = useTranslations();

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 100,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      once: true,
    },
  );

  return (
    <section className="container  max-w-none bg-slate-50 py-16 relative flex flex-col items-center justify-center">
      <Image
        src={BlueShapes}
        alt="shapes"
        className="absolute bottom-0 left-0"
        style={{
          zIndex: 0,
        }}
      />
      <div
        className="flex w-full max-w-6xl flex-col gap-10 md:gap-12 relative"
        style={{
          zIndex: 1,
        }}
      >
        <animated.div
          ref={ref}
          style={springs}
          className="flex flex-col gap-10 justify-center"
        >
          <Image src={Badge} alt="" className="self-center" />
          <div className="flex flex-col gap-7">
            <h2 className="font-semibold text-4xl text-center text-slate-800">
              {t("pages.homepage.why-us.title")}
            </h2>
            <span className="text-center text-slate-500">
              {t("pages.homepage.why-us.description")}
            </span>
          </div>
        </animated.div>
        <Separator />
        <div className="flex-col flex gap-2">
          <h3 className="font-semibold text-2xl text-center text-slate-800">
            {t("pages.homepage.why-us.what-customers-say")}
          </h3>
          <Testimonials />
        </div>
      </div>
    </section>
  );
};
