import { useRtl } from "@hooks/useRtl";
import { Text } from "@packages/ui";
import { cn } from "@packages/ui/utils/cn";
import CertificateIcon from "@public/home/certificate-01.svg";
import FreeIcon from "@public/home/coins-hand.svg";
import MessageIcon from "@public/home/message-smile-square.svg";
import RouteIcon from "@public/home/route.svg";
import { useTranslations } from "@services/strings";
import Image, { ImageProps } from "next/image";

export const Services = () => {
  const { t } = useTranslations();

  return (
    <>
      <div className="flex flex-col gap-7">
        <h2 className={"font-semibold text-3xl text-center md:text-4xl"}>
          {t("pages.homepage.services.title")}
        </h2>
        <Text className={"text-center text-slate-500"}>
          {t("pages.homepage.services.description")}
        </Text>
      </div>
      <div
        className={cn(
          "flex gap-20 mt-24 flex-col md:flex-row items-center md:items-start",
        )}
      >
        <ServiceBox
          imageSrc={MessageIcon}
          heading={t("pages.homepage.services.usp-1.title")}
          description={t("pages.homepage.services.usp-1.description")}
        />
        <ServiceBox
          imageSrc={FreeIcon}
          heading={t("pages.homepage.services.usp-2.title")}
          description={t("pages.homepage.services.usp-2.description")}
        />
        <ServiceBox
          imageSrc={CertificateIcon}
          heading={t("pages.homepage.services.usp-3.title")}
          description={t("pages.homepage.services.usp-3.description")}
        />
        <ServiceBox
          imageSrc={RouteIcon}
          heading={t("pages.homepage.services.usp-4.title")}
          description={t("pages.homepage.services.usp-4.description")}
        />
      </div>
    </>
  );
};

export const ServiceBox = ({
  imageSrc,
  heading,
  description,
}: {
  imageSrc: ImageProps["src"];
  heading: string;
  description: string;
}) => {
  const rtl = useRtl();

  return (
    <div
      className={cn(
        "flex flex-col gap-6 content-center items-center md:items-start flex-1 text-center",
        {
          "md:text-right": rtl,
          "md:text-left": !rtl,
        },
      )}
    >
      <div className="bg-orange-100 rounded-lg w-16 h-16 flex justify-center items-center">
        <Image src={imageSrc} alt={""} width={40} height={40} />
      </div>
      <Text fontWeight={"semibold"} fontSize={20}>
        {heading}
      </Text>
      <Text className={"text-slate-500"}>{description}</Text>
    </div>
  );
};
