import { useRtl } from "@hooks/useRtl";
import { Text } from "@packages/ui";
import { cn } from "@packages/ui/utils/cn";
import { useTranslations } from "@services/strings";
import { DecimalInput, DecimalInputProps } from "./DecimalInput";

export const CurrencyInput = (props: DecimalInputProps) => {
  const { currencySymbol } = useTranslations();
  const rtl = useRtl();

  return (
    <div className={"relative"}>
      <DecimalInput {...props} />
      <div
        className={cn(
          "absolute flex pointer-events-none inset-y-0 items-center px-4",
        )}
        style={{ right: rtl ? "unset" : 0, left: rtl ? 0 : "unset" }}
      >
        <Text className="text-xs text-slate-500 tracking-wide font-semibold uppercase">
          {currencySymbol}
        </Text>
      </div>
    </div>
  );
};
