import { Locale } from "@services/strings";
import { useRouter } from "next/router";

const rtlLocales: Locale[] = ["ar-EG"];

export const useRtl = () => {
  const router = useRouter();

  return rtlLocales.includes(router.locale as Locale);
};
