import { Prisma } from "@packages/data-model";
import { getAnonymousCarLoanParameters } from "@packages/data-model/services/car-equity";
import { getAnonymousHomeEquityCheckData } from "@packages/data-model/services/home-equity";
import { Separator, Text, ToggleGroup } from "@packages/ui";
import { track } from "@services/analytics";
import { CalculatorUpdatedEvent } from "@services/analytics/types";
import { useTranslations } from "@services/strings";
import debounce from "just-debounce-it";
import { useRef, useState } from "react";
import { CurrencyInput } from "../CurrencyInput";

type CalculatorMode = "home" | "car";

export const CalculatorWidget = () => {
  const { t } = useTranslations();

  const [mode, setMode] = useState<CalculatorMode>("home");
  const [income, setIncome] = useState(new Prisma.Decimal(80_000));
  const [propertyValue, setPropertyValue] = useState(
    new Prisma.Decimal(2_500_000),
  );
  const [vehicleValue, setVehicleValue] = useState(new Prisma.Decimal(500_000));

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-center">
        <ToggleGroup.Base
          defaultValue={mode}
          onValueChange={(nextValue) => setMode(nextValue as CalculatorMode)}
          className="min-h-0 p-2"
        >
          <ToggleGroup.Item value={"home"} className="px-6 h-10">
            {t("common.home")}
          </ToggleGroup.Item>
          <ToggleGroup.Item value={"car"} className="px-6 h-10">
            {t("common.car")}
          </ToggleGroup.Item>
        </ToggleGroup.Base>
      </div>
      <Separator />
      {mode === "home" ? (
        <HELCalculator
          propertyValue={propertyValue}
          income={income}
          onChange={({ income, propertyValue }) => {
            setIncome(income);
            setPropertyValue(propertyValue);
          }}
        />
      ) : (
        <CELCalculator
          vehicleValue={vehicleValue}
          income={income}
          onChange={({ income, vehicleValue }) => {
            setIncome(income);
            setVehicleValue(vehicleValue);
          }}
        />
      )}
    </div>
  );
};

const HELCalculator = ({
  propertyValue,
  income,
  onChange,
}: {
  propertyValue: Prisma.Decimal;
  income: Prisma.Decimal;
  onChange: ({
    income,
    propertyValue,
  }: {
    income: Prisma.Decimal;
    propertyValue: Prisma.Decimal;
  }) => void;
}) => {
  const { t, formatCurrency } = useTranslations();

  // Find the maximum principal for the given income and property value
  const parameters = getAnonymousHomeEquityCheckData(income, propertyValue);
  const principals = parameters.map((p) => p.principal.toNumber());
  const maxPrincipal = Math.max(...principals);

  // we use useRef() to avoid re-creating the debounced function on every re-render
  const trackCalculatorChange = useRef(
    debounce((event: CalculatorUpdatedEvent, value: Prisma.Decimal) => {
      track({
        event: "Calculator Updated",
        properties: {
          event,
          value: value.toNumber(),
        },
      });
    }, 1500),
  );

  return (
    <div className="flex flex-col gap-6 items-center w-full md:flex-row">
      <div className="flex flex-col gap-6 items-center flex-1 w-full">
        <div className="flex flex-col gap-2 w-full">
          <label
            htmlFor="property-value"
            className={
              "text-center font-semibold text-sm md:text-left md:rtl:text-right"
            }
          >
            {t("pages.hel.calculator.property-value")}
          </label>
          <CurrencyInput
            onChange={(value) => {
              onChange({
                income,
                propertyValue: value,
              });
              trackCalculatorChange.current("property-value-changed", value);
            }}
            value={propertyValue}
            id="property-value"
            className="text-center font-semibold text-sm bg-slate-100 focus:bg-slate-100 hover:bg-slate-200 border-none"
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label
            htmlFor="property-income"
            className={
              "text-center font-semibold text-sm md:text-left md:rtl:text-right"
            }
          >
            {t("pages.hel.calculator.monthly-income")}
          </label>
          <CurrencyInput
            onChange={(value) => {
              onChange({
                income: value,
                propertyValue,
              });
              trackCalculatorChange.current("income-changed", value);
            }}
            id="property-income"
            value={income}
            className="text-center font-semibold text-sm bg-slate-100 focus:bg-slate-100 hover:bg-slate-200 border-none"
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <Text className={"text-center text-slate-500"}>
          {t("pages.hel.calculator.borrow-up-to")}
        </Text>
        <Text fontWeight={"semibold"} className={"text-center text-3xl"}>
          {maxPrincipal ? formatCurrency(maxPrincipal) : "-"}
        </Text>
      </div>
    </div>
  );
};

const CELCalculator = ({
  vehicleValue,
  income,
  onChange,
}: {
  vehicleValue: Prisma.Decimal;
  income: Prisma.Decimal;
  onChange: ({
    income,
    vehicleValue,
  }: {
    income: Prisma.Decimal;
    vehicleValue: Prisma.Decimal;
  }) => void;
}) => {
  const { t, formatCurrency } = useTranslations();

  // Find the maximum principal for the given income and vehicle value
  const parameters = getAnonymousCarLoanParameters(income, vehicleValue);
  const principals = parameters.map((p) => p.principal.toNumber());
  const maxPrincipal = Math.max(...principals);

  // we use useRef() to avoid re-creating the debounced function on every re-render
  const trackCalculatorChange = useRef(
    debounce((event: CalculatorUpdatedEvent, value: Prisma.Decimal) => {
      track({
        event: "Calculator Updated",
        properties: {
          event,
          value: value.toNumber(),
        },
      });
    }, 1500),
  );

  return (
    <div className="flex flex-col md:flex-row gap-6 items-center">
      <div className="flex flex-col gap-6 items-center flex-1 w-full">
        <div className="flex flex-col gap-2 w-full">
          <label
            htmlFor="vehicle-value"
            className={
              "text-center font-semibold text-sm md:text-left md:rtl:text-right"
            }
          >
            {t("pages.homepage.calculator.car-value")}
          </label>
          <CurrencyInput
            onChange={(value) => {
              onChange({
                income,
                vehicleValue: value,
              });
              trackCalculatorChange.current("vehicle-value-changed", value);
            }}
            id="vehicle-value"
            value={vehicleValue}
            className="text-center font-semibold text-sm bg-slate-100 focus:bg-slate-100 hover:bg-slate-200 border-none"
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label
            htmlFor="vehicle-income"
            className={
              "text-center font-semibold text-sm md:text-left md:rtl:text-right"
            }
          >
            {t("pages.hel.calculator.monthly-income")}
          </label>
          <CurrencyInput
            onChange={(value) => {
              onChange({
                income: value,
                vehicleValue,
              });
              trackCalculatorChange.current("income-changed", value);
            }}
            id="vehicle-income"
            value={income}
            className="text-center font-semibold text-sm bg-slate-100 focus:bg-slate-100 hover:bg-slate-200 border-none"
          />
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-2">
        <Text className={"text-center text-slate-500"}>
          {t("pages.hel.calculator.borrow-up-to")}
        </Text>
        <Text fontWeight={"semibold"} className={"text-center text-3xl"}>
          {maxPrincipal ? formatCurrency(maxPrincipal) : "-"}
        </Text>
      </div>
    </div>
  );
};
