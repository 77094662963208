import { track } from "@services/analytics";
import { useTranslations } from "@services/strings";
import Image from "next/image";
import AppStore from "../../public/app/appstore.svg";
import PlayStoreIcon from "../../public/app/playstore.svg";

export const AppStoreButton = ({ store }: { store: "apple" | "android" }) => {
  const { t } = useTranslations();
  const storeUrl =
    store === "apple"
      ? "https://apps.apple.com/eg/app/mnzl-wallet/id6477818458"
      : "https://play.google.com/store/apps/details?id=co.mnzl.wallet";

  const trackClick = () => {
    track({
      event: "Button Clicked",
      properties: {
        label: `App Store Button - ${store}`,
      },
    });
  };

  return (
    <a
      target={"_blank"}
      href={storeUrl}
      onClick={trackClick}
      className={"bg-black rounded-lg"}
      aria-label={t("pages.homepage.download-app-aria")}
    >
      {store === "apple" && <Image src={AppStore} alt={""} />}
      {store === "android" && <Image src={PlayStoreIcon} alt={""} />}
    </a>
  );
};
