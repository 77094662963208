import { Header } from "@components/Header";
import { Services } from "@components/app-page/services-section";
import PhoneScreen from "@public/app/phone_screen.png";
import PhoneScreenAR from "@public/app/phone_screen_ar.png";
import Image from "next/image";

import { Footer } from "@components/Footer";
import { breakpoint } from "@packages/ui";
import { useTranslations } from "@services/strings";

import useDimensions from "react-cool-dimensions";
import { CalculatorWidget } from "../components/app-page/calculator";

import { AppStoreButton } from "@components/app-page/app-store-button";
import { CTASection } from "@components/app-page/cta-section/cta-section";
import { PressSection } from "@components/app-page/pr-section";
import { TestimonialSection } from "@components/app-page/testimonial-section/testimonial-section";
import { useRtl } from "@hooks/useRtl";
import { animated, useInView, useSpring } from "@react-spring/web";
import Head from "next/head";

const AppPage = () => {
  const { t } = useTranslations();
  const isRtl = useRtl();
  const { observe, currentBreakpoint } = useDimensions({
    breakpoints: { ...breakpoint },
    updateOnBreakpointChange: true,
  });

  const [colorRef, colorSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    }),
    {
      once: true,
    },
  );

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 100,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-40% 0%",
      once: true,
    },
  );

  const style = useSpring({
    from: {
      opacity: 0,
      x: 100,
    },
    to: {
      opacity: 1,
      x: 0,
    },
  });

  return (
    <div ref={observe} tabIndex={0}>
      <Head>
        <title>{t("pages.homepage.splash.title")}</title>
        <meta
          name="description"
          content={t("pages.homepage.meta-description")}
        ></meta>
      </Head>
      <Header />
      <section className="container max-w-none flex justify-center relative overflow-hidden">
        <animated.div
          ref={colorRef}
          style={colorSprings}
          className="absolute inset-0 bottom-0"
        >
          <div
            style={{
              position: "absolute",
              left: "0px",
              right: "0px",
              bottom: "0px",
              height: currentBreakpoint === "small" ? "430px" : "500px",
              borderColor: "transparent",
              borderTopWidth: currentBreakpoint === "small" ? "80px" : "280px",
              borderInlineEndWidth: "100vw",
              borderInlineEndStyle: "solid",
              borderInlineEndColor: "#FDBA74",
            }}
          />
        </animated.div>

        <div className="max-w-6xl w-full flex flex-col justify-center gap-16 relative pt-10 md:justify-start md:flex-row">
          <animated.div
            ref={ref}
            style={springs}
            className="flex flex-1 max-w-lg flex-col gap-12 self-center md:self-auto"
          >
            <div className="flex flex-col gap-7 justify-center md:justify-start md:mt-20">
              <h1 className="font-semibold text-3xl text-center md:text-left md:rtl:text-right md:text-4xl">
                {t("pages.homepage.splash.title")}
              </h1>
              <span className="text-center text-slate-500 md:text-left md:rtl:text-right">
                {t("pages.homepage.splash.description")}
              </span>
            </div>

            <div className="flex justify-center md:justify-start">
              <div className="flex flex-row gap-4 place-self-center">
                <AppStoreButton store="apple" />
                <AppStoreButton store="android" />
              </div>
            </div>
          </animated.div>
          <animated.div
            style={style}
            className="flex flex-1 justify-center md:justify-end md:mr-10"
          >
            <Image
              src={isRtl ? PhoneScreenAR : PhoneScreen}
              alt="app"
              className="self-center w-80 md:w-96"
              priority={true}
              placeholder="blur"
              style={{
                zIndex: 2,
              }}
            />
          </animated.div>
        </div>
      </section>
      <section className="container max-w-none bg-slate-50 pb-20 flex justify-center">
        <div className="max-w-6xl flex flex-col gap-20 -mt-60 flex-1 w-full">
          <div
            className="drop-shadow-2xl p-8 bg-white rounded-xl flex flex-col gap-8"
            style={{
              zIndex: 2,
            }}
          >
            <h2 className="font-semibold text-xl md:text-3xl text-center">
              {t("pages.hel.calculator.title")}
            </h2>
            <CalculatorWidget />
          </div>
          <div className="flex flex-col gap-10">
            <PressSection />
          </div>
        </div>
      </section>
      <section className="container max-w-6xl py-16">
        <Services />
      </section>
      <TestimonialSection />
      <CTASection />
      <Footer />
    </div>
  );
};

export default AppPage;
