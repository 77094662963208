import AsharqLogo from "@public/home/press/bloomberg_logo.svg";
import CairoSceneLogo from "@public/home/press/cairo_scene_logo.png";
import EnterpriseLogo from "@public/home/press/enterprise_logo.svg";
import ForbesLogo from "@public/home/press/forbes_logo_black.svg";
import MenaBytesLogo from "@public/home/press/menabytes_logo.png";
import WamdaLogo from "@public/home/press/wamda-logo.png";
import { useTranslations } from "@services/strings";
import Image from "next/image";
import Marquee from "react-fast-marquee";
import { track } from "@services/analytics";
import Certified from "@public/app/certified_badge.png";

const pressItems = [
  {
    alt: "Forbes Middle East",
    logo: ForbesLogo,
    href: "https://www.forbesmiddleeast.com/innovation/startups/egypt-based-mnzl-raises-%2435m-in-funding-round-to-revolutionize-asset-backed-lending-in-egypt",
  },
  {
    alt: "Asharq News",
    logo: AsharqLogo,
    href: "https://now.asharq.com/clip/%D8%AA%D8%B7%D8%A8%D9%8A%D9%82-mnzl-%D9%84%D9%84%D8%AA%D9%83%D9%86%D9%88%D9%84%D9%88%D8%AC%D9%8A%D8%A7-%D8%A7%D9%84%D9%85%D8%A7%D9%84%D9%8A%D8%A9-%D9%8A%D8%AC%D9%85%D8%B9-35-%D9%85%D9%84%D9%8A%D9%88%D9%86-%D8%AF%D9%88%D9%84%D8%A7%D8%B1",
  },
  {
    alt: "Enterprise",
    logo: EnterpriseLogo,
    href: "https://enterprise.news/egypt/en/news/story/50e3316a-ae1c-4bbb-80b6-c6f4b13a812d/fintech-startup-mnzl-raises-usd-3.5-mn-in-funding-round",
  },
  {
    alt: "Wamda",
    logo: WamdaLogo,
    href: "https://www.wamda.com/2024/05/egypt-mnzl-raises-3-5-million-seed-led-p1-ventures",
  },
  {
    alt: "Cairo Scene",
    logo: CairoSceneLogo,
    href: "https://cairoscene.com/Business/Fintech-Startup-MNZL-Raises-3-5M-to-Introduce-New-Lending-Options",
  },
  {
    alt: "Mena Bytes",
    logo: MenaBytesLogo,
    href: "https://www.menabytes.com/mnzl-investment/",
  },
];

export const PressSection = () => {
  const { t } = useTranslations();

  const trackLinkClick = (label: string) => {
    track({
      event: "Link Clicked",
      properties: {
        label,
      },
    });
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-6 justify-center items-center">
        <p className="text-slate-500">{t("pages.homepage.featured-in")}</p>
        <div className="overflow-hidden w-full max-w-full">
          <Marquee
            direction={"left"}
            gradient={true}
            gradientColor="#F8FAFC"
            pauseOnHover={true}
            className={"gap-9"}
            style={{ direction: "ltr" }}
          >
            <ul className="flex flex-row gap-9 items-center justify-center">
              {[...pressItems, ...pressItems].map((item, index) => (
                <li key={index}>
                  <a
                    href={item.href}
                    target="_blank"
                    onClick={() => trackLinkClick(item.alt)}
                  >
                    <Image src={item.logo} alt={item.alt} height={28} />
                  </a>
                </li>
              ))}
            </ul>
          </Marquee>
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-center items-center">
        <Image src={Certified} alt="certified" height={24} width={24} />
        <span className="text-sm text-center text-slate-500">
          {t("pages.car.hp-section.certified-by-fra")}
        </span>
      </div>
    </div>
  );
};
